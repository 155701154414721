'use client';

import posthog from 'posthog-js';
import { PostHogProvider as PHProvider } from 'posthog-js/react';
import { useEffect } from 'react';
export function PostHogProvider({
  children
}: {
  children: React.ReactNode;
}) {
  useEffect(() => {
    if (!process.env.NEXT_PUBLIC_POSTHOG_KEY) {
      return;
    }
    posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
      api_host: '/ph',
      person_profiles: 'identified_only',
      capture_pageview: false,
      debug: !!process.env.NEXT_PUBLIC_DEBUG
    });
  }, []);
  return <PHProvider client={posthog} data-sentry-element="PHProvider" data-sentry-component="PostHogProvider" data-sentry-source-file="PostHogProvider.tsx">{children}</PHProvider>;
}
import(/* webpackMode: "eager", webpackExports: ["NextSSRPlugin"] */ "/vercel/path0/node_modules/.pnpm/@uploadthing+react@7.1.3_next@14.2.20_@babel+core@7.26.0_@opentelemetry+api@1.9.0_react-dom@1_s6wsoojabpa6iy73kbiyz2pv5q/node_modules/@uploadthing/react/next-ssr-plugin/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/node_modules/.pnpm/@vercel+analytics@1.4.1_next@14.2.20_@babel+core@7.26.0_@opentelemetry+api@1.9.0_react-dom@18_7qvw2ec3pqayem5kcgfkbduzdm/node_modules/@vercel/analytics/dist/react/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/node_modules/.pnpm/@vercel+speed-insights@1.1.0_next@14.2.20_@babel+core@7.26.0_@opentelemetry+api@1.9.0_react-d_sqa6u34k3aznkk7hxteecd7eai/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/material-symbols@0.20.0/node_modules/material-symbols/index.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.20_@babel+core@7.26.0_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.82.0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.20_@babel+core@7.26.0_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.82.0/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.20_@babel+core@7.26.0_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.82.0/node_modules/next/font/local/target.css?{\"path\":\"src/components/shared/Root/Root.tsx\",\"import\":\"\",\"arguments\":[{\"display\":\"swap\",\"variable\":\"--font-reddit-sans\",\"fallback\":[\"system-ui\",\"sans-serif\"],\"src\":[{\"path\":\"../../../../assets/fonts/RedditSans-Variable.ttf\"},{\"path\":\"../../../../assets/fonts/RedditSans-Italic-Variable.ttf\",\"style\":\"italic\"}]}],\"variableName\":\"reddit\"}");
;
import(/* webpackMode: "eager", webpackExports: ["PasswordPromptDialog"] */ "/vercel/path0/src/components/shared/PasswordPromptDialog/PasswordPromptDialog.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/shared/Posthog/PostHogPageView.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PostHogProvider"] */ "/vercel/path0/src/components/shared/Posthog/PostHogProvider.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/styles/globals.scss");
